@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap");

@font-face {
  font-family: "SF Pro Text Regular";
  src: url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.eot");
  /* IE9*/
  src: url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.woff2") format("woff2"),
    /* chrome、firefox */
    url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.woff") format("woff"),
    /* chrome、firefox */
    url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../asset/fonts/1c45e28f8e86cc89876f003b953cc3e9.svg#SF Pro Text Regular") format("svg");
  /* iOS 4.1- */
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: block;
}

.mainListSection::-webkit-scrollbar {
  display: none;
}
.MuiTablePagination-selectLabel{
  color: #6B7280;
}
.css-18n2980-MuiSelect-select-MuiInputBase-input{
  border: 2px solid gray;
  border-radius: 7px;
  padding: 8px 20px 8px 10px;
}
.MuiPaginationItem-circular {
  border-radius: 5px;
  margin: 0px 1px;
}
.MuiPaginationItem-previousNext  {
  background: #145DA0;
}
.MuiPaginationItem-icon {
  color:#FFFFFF;
}
.css-15gyu4h-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected{
  color:#FFFFFF;
  background: #145DA0;
}
.makeStyles-cardSortDownIcon-421,.makeStyles-cardSortUpIcon-422 {
  font-size: 33px;
  transform: rotate(270deg);
  margin-bottom: 2px;
}
.css-t9iwi6-MuiTypography-root,.MuiInputAdornment-root {
  color: #37474F;
}
body {
  font-family: "Inter" !important;
}

.ReactModal__Overlay {
  z-index: 2070 !important;
}

.css-pxxd1-MuiImageListItem-root {
  cursor: pointer !important;
}

.css-1o2jng6-MuiOutlinedInput-notchedOutline {
  border-color: #2e8bc0 !important;
}

/* .css-wtw5vo-MuiInputBase-input-MuiOutlinedInput-input {
  height: 2px !important;
} */

@media (min-width:900px) {
  .MuiInputBase-inputMultiline {
    height: auto !important;
  }

  .MuiOutlinedInput-input {
    height: 2px;
  }
}


/* .css-1od52cu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 8px !important;
}

.css-1by1m50-MuiInputBase-input-MuiOutlinedInput-input {
  height: 5px !important;
} */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E6E8F0;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C0C7CA;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C0C7CA;
}

.swal2-container {
  z-index: 1200 !important;
}

.css-qfz70r-MuiFormGroup-root {
  display: unset !important;
}

.MuiCheckbox-root:hover {
  background: none;
}

.css-1uaz6xt-MuiPaper-root-MuiDrawer-paper {
  top: 0px !important;
  /* z-index: 1400; */
  height: calc(100% - 0px) !important;
}

.swal2-cancel {
  /* display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; */
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: Inter, "sans-serif" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  min-width: 85px !important;
  padding: 12px 23px !important;
  border-radius: 8px !important;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #ffffff !important;
  background-color: #145da0 !important;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%) !important;
  text-transform: none !important;
  margin-top: 8px !important;
  margin-right: 8px !important;
  background-color: #C44D56 !important;
  color: #ffffff !important;
}

.swal2-cancel:disabled{
  background-color: #dddddd !important;
  color: rgb(144, 142, 142) !important;
}

.swal2-confirm {
  /* display: -webkit-inline-box !important;
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; */
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  font-family: Inter, "sans-serif" !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  text-transform: uppercase !important;
  min-width: 85px !important;
  padding: 12px 23px !important;
  border-radius: 8px !important;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  color: #ffffff !important;
  background-color: #145da0 !important;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%), 0px 0px 0px 1px rgb(0 0 0 / 5%) !important;
  text-transform: none !important;
  margin-top: 8px !important;
  background-color: #145DA0;
  color: #ffffff;
}

.swal2-popup {
  border-radius: 8px !important;
  width: 476px !important;
}

.swal2-title {
  font-weight: 700 !important;
  color: #145da0 !important;
  font-size: 40px !important;
  line-height: 40px !important;
}

.swal2-html-container {
  color: #145da0 !important;
  font-size: 20px !important;
  line-height: 40px !important;
  color: #145da0 !important;
  letter-spacing: 0.15px !important;
  font-weight: 700 !important;
}

.swal2-icon.swal2-warning {
  background: #145da0 !important;
  color: #fafbfd !important;
  border-color: #145da0 !important;
  margin-bottom: 30px !important;
  margin-top: 60px !important;
}

.swal2-actions {
  margin: 0 auto 0 !important;
}

@media print {
  .noBreakPage {
    page-break-inside: avoid;
  }
}

.login-profile-icons-maindiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.profile-icons {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.logout {
  display: flex;
  align-items: center;
}

.profile-icons img,
.logout img {
  margin-right: 10px;
}

.logo-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.logo-sec-mobile {
  width: 89%;
}

.main-header-sec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.login-profile-icons-maindiv a {
  text-decoration: none;
}

.login-profile-icons-maindiv h6 {
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  color: #fff;
}

.css-1egk6b4-MuiButtonBase-root-MuiButton-root {
  color: #fff;
  border: 1px solid #fff;
}

.css-1egk6b4-MuiButtonBase-root-MuiButton-root:disabled {
  background: #145da0;
  color: #f7f7f7;
  border-color: #f7f7f7;
}

.css-yj6p2f {
  padding-top: 60px;
}

.MuiChip-deleteIcon {
  color: rgb(250 250 250) !important;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.css-19di2ru {
  padding-bottom: 0px !important;
}

.print-content {
  display: none;
}

@media print {
  .print-content {
    display: block;
  }
}

.css-ei7t0p-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 7.5px 0px 10.5px 6px !important;
}

.MuiAutocomplete-input {
  padding: 10px 0px 10.5px 6px !important;
}

.filterlabel label {
  justify-content: space-between;
  flex-direction: row-reverse;
}

.filterFormControl label {
  justify-content: space-between;
  margin: 0;
}

@page {
  padding: 50px;
}

@media print {
  table {
    page-break-inside: auto
  }

  tr {
    page-break-inside: avoid;
    page-break-after: auto
  }

  thead {
    display: table-header-group
  }

  tfoot {
    display: table-footer-group
  }

  @page {
    padding: 50px;
  }

  .page-break-avoid {
    page-break-inside: avoid;
  }

  .no-page-break {
    display: inline-block;
    width: 100%;
    page-break-inside: avoid;
  }

  .page-break {
    page-break-before: always;
  }

  .order-container {
    page-break-after: always;
  }
}


/* .css-1wh5z5o-MuiInputBase-root-MuiOutlinedInput-root{
  max-width: 205px;
} */

.error {
  color: red;
  font-size: 15px;
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  min-height: 35px !important;
  padding: 1px 50px 1px 4px !important;
}

.MuiAutocomplete-root .MuiAutocomplete-tag {
  max-width: calc(100% - 0px) !important;
}

.carousel-slider {
  position: relative;
}

.btn-prev {
  position: absolute;
  left: -33px;
  top: 45%;
}

.btn-next {
  position: absolute;
  right: -33px;
  top: 45%;
}

.MuiInputLabel-root {
  line-height: 19px;
}

.MuiContainer-root {
  padding-left: 16px;
  padding-right: 16px;
}

.MuiInputBase-multiline {
  padding: 0px 5px;
}

.MuiInputBase-inputMultiline {
  padding: 8.5px 10px;
}

.ql-editor p {
  margin: 0px !important;
}
.ql-editor {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.quill {
  border: 1.2px solid #37474F;
  border-radius: 8px;
  padding: 0px 14px;
  font-size: 14px;
  line-height: 1.4375em;
  font-weight: 600;
  color: #172b4d;
  border-style: solid;
  display: flex;
  flex-direction: column;
}

.quill.ql-snow.ql-toolbar {
  display: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.quill .ql-snow .ql-toolbar .ql-picker-label:hover {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar .ql-picker-label .ql-active {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar .ql-picker-item:hover {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar .ql-picker-item .ql-selected {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar button:hover {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar button:focus {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar button:focus .ql-stroke {
  color: #42526e,
}

.quill .ql-snow .ql-toolbar button.ql-active {
  color: #42526e,
}

.quill .ql-snow .ql-container {
  border-bottom: none;
  border-left: none;
  border-right: none;
  flex-grow: 1;
}

.quill .ql-container .ql-tooltip {
  display: none;
}

.quill .ql-container .ql-clipboard {
  display: none;
}

.quill .ql-container .ql-editor:focus-visible {
  outline: none;
}

.quill .ql-snow .ql-container .ql-editor .ql-blank::before {
  font-style: normal;
}

.quill .ql-snow .ql-container .ql-editor {
  color: #42526e;
}

.btn-learn-prev {
  position: absolute;
  left: -60px;
  top: 45%;
}

.btn-learn-next {
  position: absolute;
  right: -60px;
  top: 45%;
}

@keyframes spin {
  0% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.MuiTableSortLabel-icon {
  opacity: 1;
}

@media(min-width: 600px) {
  .MuiTabs-root .MuiButtonBase-root {
    min-width: unset !important;
  }
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker-year-header {
  background: #135da0 !important;
  color: #fff !important;
}

.react-datepicker__header {
  border-bottom: unset !important;
  background: #135da0 !important;
}

.yarl__navigation_prev {

  left: 28% !important;

}

.yarl__navigation_next {

  flex-direction: column;

  right: 28% !important;

}

.yarl__navigation_next,
.yarl__navigation_prev {
  background: #145DA0 !important;
  justify-content: center;
  border-radius: 8px;

  padding: 10px !important;
}


.yarl__container {
  background: rgb(7 6 6 / 90%) !important;
}

.yarl__toolbar {
  top: 24% !important;
  right: 26% !important;
  background: #145DA0 !important;
  border-radius: 8px;
  padding: 0px !important;
  color: #fff !important;
}

/* @media only screen and (max-width:1600px){
  .yarl__navigation_next{
    right: 20% !important;
  }
  .yarl__navigation_prev{
    left: 20% !important;
  }
} */
@media screen and (min-device-width: 1699px) and (max-device-width: 1920px) {
  .yarl__navigation_next {
    right: 27% !important;
  }

  .yarl__navigation_prev {
    left: 27% !important;
  }

  .yarl__toolbar {
    top: 24% !important;
    right: 32% !important;
  }

  .swiper-button-prev {
    left: -5% !important;
  }

  .swiper-button-next {
    right: -5% !important;
  }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) {
  .yarl__navigation_next {
    right: 22% !important;
  }

  .yarl__navigation_prev {
    left: 22% !important;
  }

  .yarl__toolbar {
    top: 23.4% !important;
    right: 28% !important;
  }

  .swiper-button-prev {
    left: -7% !important;
  }

  .swiper-button-next {
    right: -7% !important;
  }
}

@media screen and (min-device-width: 1024px) and (max-device-width: 1200px) {
  .yarl__navigation_next {
    right: 14% !important;
  }

  .yarl__navigation_prev {
    left: 14% !important;
  }

  .yarl__toolbar {

    right: 21% !important;
  }

  .swiper-button-prev {
    left: -7% !important;
  }

  .swiper-button-next {
    right: -7% !important;
  }
}

@media screen and (min-device-width: 991px) and (max-device-width: 1024px) {
  .yarl__navigation_next {
    right: 8% !important;
  }

  .yarl__navigation_prev {
    left: 8% !important;
  }

  .yarl__toolbar {

    right: 17% !important;
  }

  .swiper-button-prev {
    left: -7% !important;
  }

  .swiper-button-next {
    right: -7% !important;
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: initial !important;
  background-color: transparent !important;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 170px !important;
  height: 140px !important;
  opacity: 0.8;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-prev {
  background-color: #145DA0;
  color: #fff !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 8px !important;
  padding: 10px !important;
  font-size: 20px !important;
  left: -5%;
  top: 41% !important;
}

.swiper-button-next {
  background-color: #145DA0;
  color: #fff !important;
  width: 50px !important;
  height: 50px !important;
  border-radius: 8px !important;
  padding: 10px !important;
  font-size: 20px !important;
  right: -5%;
  top: 41% !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.swiper-slide img {
  border-radius: 8px;
}

.swiper-backface-hidden .swiper-slide {
  padding: 5px;
  background: transparent !important;
}

.MuiFormHelperText-root.Mui-error {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.15px;
  color: #C44D56;
  margin-left: 0px;
}

/* .MuiTypography-root {
  color: #37474F;
} */

.MuiTooltip-tooltip{
  white-space: nowrap !important;
  max-width: 100% !important;
  padding: 8px !important;
}

.MuiSelect-select{
  padding: 5.5px 14px !important;
}